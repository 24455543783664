@import "styles/reset";
@import "styles/form-field";
@import "styles/variables";
@import "styles/font";
@import "styles/titles";
@import "styles/theme";
@import "styles/variables";
@import "styles/button";
@import "styles/input";
@import "styles/reset";
@import "styles/chip";
@import "styles/dialog";
@import "styles/tab";
@import "styles/snakbar";
@import "styles/global";
@import "styles/pdf-generation-table";

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: var(--bg-body);
}

body {
  display: block;
  width: 100vw;
  overflow-x: hidden;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.material-symbols-rounded {
  font-variation-settings: 'FILL' 0,
  'wght' 400,
  'GRAD' -25,
  'opsz' 24
}

.flex-placeholder {
  flex: 1;
}

.pointer {
  cursor: pointer;
}
