@import "variables";

.mat-form-field-appearance-fill {
  .mat-mdc-text-field-wrapper {
    border: 1px solid var(--tonedBlackGrey300);
    background-color: var(--tonedBlackGrey050);
    border-radius: 8px;

    input.mat-input-element,
    textarea.mat-input-element {
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
    }

    .mat-form-field-label-wrapper {
      top: -1.3em;
      padding-top: 1.3em;
    }

    .mat-form-field-outline-start {
      // Angular Material can set width of the element to 0px and it will make this radius small
      width: 14px !important;
    }

    .mdc-line-ripple {
      display: none;
    }

    .mat-mdc-form-field-focus-overlay {
      background-color: transparent;
    }


  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: var(--tonedBlackGrey050);
  }

  mat-hint {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.4px;

    color: var(--tonedBlackGrey700);
  }

  mat-error {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.4px;

    color: var(--warn);
  }

  &.no-border {
    .mat-mdc-text-field-wrapper {
      // we don't want to remove border as structurally it should be there just not visible
      // otherwise, the input jumps up and down when focused and border is introduced.
      border-color: var(--white);
    }
  }
}

.student-answer-shared.mat-form-field-appearance-fill:not(.mat-focused) .mdc-text-field--filled {
  background-color: white;
}

.student-answer-private.mat-form-field-appearance-fill:not(.mat-focused) .mdc-text-field--filled {
  background: white;
}

.mat-form-field-appearance-fill.mat-focused {
  .mat-mdc-text-field-wrapper {
    border: 1px solid var(--primary-color);
    background-color: var(--white);

    .mdc-line-ripple {
      display: none;
    }

    .mdc-floating-label {
      color: var(--brandPrimary);
    }
  }
}

.mat-form-field-invalid {
  .mat-mdc-text-field-wrapper {
    border: 1px solid var(--warn);
    background-color: var(--white);

    .mdc-line-ripple {
      display: none;
    }

    .mdc-floating-label {
      color: var(--warn);
    }
  }
}

mat-chip-grid.mat-mdc-chip-grid input {
  min-height: 36px;
}
