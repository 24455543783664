@import "variables";

.mdc-button.mat-mdc-button-base {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;

  border-radius: 8px;
  padding: 10px 16px;

  &:not(:disabled) {
    color: var(--tonedBlackGrey500);
  }

  background-color: var(--white);
  border: none;
  box-shadow: none;

  &[color="primary"] {
    color: var(--brandPrimary);
  }

  &[color="accent"] {
    color: var(--errorLight);
  }

  &[disabled][disabled] {
    color: var(--tonedBlackGrey200);
  }

  .mdc-button__label {
    /*height: 18px;*/
    line-height: 18px;
  }

  mat-icon + .mdc-button__label:not(:empty) {
    margin-left: 8px;
  }

  &.mat-mdc-raised-button {
    color: var(--tonedBlackGrey500);

    &[color="primary"], &[ng-reflect-color="primary"] {
      color: var(--white);
    }

    &[disabled][disabled] {
      cursor: not-allowed;
      pointer-events: all;
      color: var(--white-disabled);
      background-color: var(--tonedBlackGrey200);
    }
  }

  &.mat-mdc-unelevated-button {
    color: var(--tonedBlackGrey500);
    background-color: var(--tonedBlackGrey025);
    box-shadow: 0px 0px 2px rgba(61, 3, 163, 0.16), 0px 2px 6px rgba(61, 3, 163, 0.32);

    &[color="primary"] {
      background-color: var(--brandLightest);
      color: var(--brandPrimary);
    }

    &[color="accent"] {
      background-color: var(--errorLightest);
      color: var(--errorLight);
    }

    &[disabled][disabled] {
      color: var(--tonedBlackGrey200);
      background-color: var(--tonedBlackGrey075);
      box-shadow: none;
    }
  }

  &.mat-mdc-outlined-button {
    color: var(--tonedBlackGrey500);
    background-color: transparent;
    border: 1px solid var(--tonedBlackGrey500);

    &[color="primary"] {
      color: var(--brandPrimary);
      border-color: var(--brandPrimary);
    }

    &[color="accent"] {
      color: var(--errorLight);
      border-color: var(--warn);
    }

    &[disabled][disabled] {
      color: var(--tonedBlackGrey200);
      border-color: var(--tonedBlackGrey200);
    }
  }

  mat-icon {
    margin-right: 2px;
    margin-bottom: 1px;
  }
}

.mdc-button.mat-mdc-button-base:has(> mat-icon) {
  padding: 10px;
  min-width: 36px;

  mat-icon {
    margin: 0;
  }
}
