@import "variables";

mat-dialog-container.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 8px;
  box-shadow: 0px 15px 12px rgba(63, 68, 84, 0.22), 0px 19px 38px rgba(63, 68, 84, 0.3);
  background-color: var(--tonedBlackGrey025);
  padding: 16px;
  width: max(100vw, 500px);

  mat-dialog-actions {
    margin-top: 18px;
    display: flex;
    padding: 0;
    flex-direction: column;
    gap: 16px;

    button.mat-mdc-button-base {
      width: 100%;
      margin: 0;
    }
  }
}
