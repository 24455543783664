@import "variables";

.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) span.mdc-evolution-chip__text-label.mat-mdc-chip-action-label {
  color: var(--white);
}

.chips.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.4px;
  height: 22px;
  min-height: 22px;
  padding: 0;
}


.chips-shared.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  @extend .chips;

  background-color: var(--chipsSecondary);
}

.chips-private.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  @extend .chips;

  background-color: var(--chipsPrivate);
}

.chips-mandatory.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  @extend .chips;

  background-color: var(--chipsPrimary);
}

.chips-optional.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  @extend .chips;

  background-color: var(--chipsOptional);
}

.chips-not-registered.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  @extend .chips;

  background-color: var(--chipsNotRegistered);
}

.chips-not-started.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  @extend .chips;

  background-color: var(--chipsNotStarted);
}

.chips-started.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  @extend .chips;

  background-color: var(--chipsStarted);
}

.chips-complete.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  @extend .chips;

  background-color: var(--chipsComplete);
}

.chips-email.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background-color: var(--chipsEmail);
  border: 1px solid var(--brandLight);

  span.mdc-evolution-chip__text-label.mat-mdc-chip-action-label {
    color: var(--brandPrimary);
  }
}

.chips-email-warn.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background: var(--brandLightest);
  border: 1px solid var(--warn);

  span.mdc-evolution-chip__text-label.mat-mdc-chip-action-label {
    color: var(--warn);
  }
}

.program-info mat-chip mat-icon {
  width: 16px;
  height: 16px;
  font-size: 16px;

  color: var(--tonedBlackGrey700);
}
