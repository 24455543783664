@use '../../node_modules/@angular/material/index' as mat;

@include mat.core();

/*$fnol-palette: (
        50: var(--color-secondary),
        100: var(--color-secondary),
        200: var(--color-secondary),
        300: var(--color-secondary),
        400: var(--color-secondary),
        500: var(--color-secondary),
        600: var(--color-secondary),
        700: var(--color-secondary),
        800: var(--color-secondary),
        900: var(--color-secondary),
        A100: var(--color-secondary),
        A200: var(--color-secondary),
        A400: var(--color-secondary),
        A700: var(--color-secondary),
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #000000,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #000000,
                A700 : #ffffff,
        )
);*/

$calingo-claim-management-primary-palette: (
  50: var(--lighter-secondary-color),
  100: var(--lighter-secondary-color),
  200: var(--lighter-secondary-color),
  300: var(--lighter-secondary-color),
  400: var(--primary-color),
  500: var(--primary-color),
  600: var(--dark-primary-color),
  700: var(--dark-primary-color),
  800: var(--dark-primary-color),
  900: var(--dark-primary-color),
  A100: var(--accent-color),
  A200: var(--lighter-accent-color),
  A400: var(--darker-accent-color),
  A700: var(--darker-accent-color),
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  )
);

$calingo-claim-management-accent-palette: (
  50: var(--lighter-accent-color),
  100: var(--lighter-accent-color),
  200: var(--lighter-accent-color),
  300: var(--lighter-accent-color),
  400: var(--accent-color),
  500: var(--accent-color),
  600: var(--darker-accent-color),
  700: var(--darker-accent-color),
  800: var(--darker-accent-color),
  900: var(--darker-accent-color),
  A100: var(--accent-color),
  A200: var(--lighter-accent-color),
  A400: var(--darker-accent-color),
  A700: var(--darker-accent-color),
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #ffffff,
  )
);

$pet-fnol-fe-primary: mat.define-palette($calingo-claim-management-primary-palette);
$pet-fnol-fe-accent: mat.define-palette($calingo-claim-management-accent-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$pet-fnol-fe-warn: mat.define-palette(mat.$red-palette);

$pet-fnol-fe-theme: mat.define-light-theme((
  color: (
    primary: $pet-fnol-fe-primary,
    accent: $pet-fnol-fe-accent,
    warn: $pet-fnol-fe-warn,
  )
));

@include mat.all-component-themes($pet-fnol-fe-theme);
