@import 'variables';


mat-tab-header {
  border-bottom: 1px solid var(--tonedBlackGrey075);
}

mat-tab-header .mat-mdc-tab-labels {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.25px;
  text-transform: uppercase;
}
