.info {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Assumes each grid item will be at least 200px, change as per requirement */
  grid-gap: 10px 20px; /* row-gap then column-gap */
  width: 100%;

  div {
    display: flex;
    flex-direction: column;
    gap: 0;

    label {
      font-size: 10px;
      line-height: 100%;
      color: var(--tonedBlackGrey200);
    }

    &.email {
      p {
        word-break: break-all;
      }
    }
  }

  div.info-value-with-search {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }

  &-search-div {
    border-radius: 5px;
    background-color: var(--color-secondary);
    color: white;
    cursor: pointer;
  }
}

.small-info {
  display: flex;
  flex-grow: 1;
  align-items: center;
  gap: 20px;
  flex-wrap: wrap;

  span {
    display: flex;
    align-items: center;
    gap: 5px;

    min-width: 200px;

    mat-icon {
      color: var(--primary-color);
    }
  }
}

.expand-button {
  cursor: pointer;
  transition: transform 0.3s;

  font-variation-settings: 'FILL' 0,
  'wght' 400,
  'GRAD' -25,
  'opsz' 48;

  &.expand {
    transform: rotate(180deg);
  }
}

.info.treatment {
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 20px;
}

.info > .treatment-pet-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 20px;
}

.position-titles.info.treatment-position {
  background-color: white;
}

.info.treatment-position {
  padding: 1px;
  background-color: var(--tonedBlackGrey025);
  border-radius: 20px;
  /*box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);*/

  display: flex;
  flex-direction: row;

  div {
    width: 15%;
  }

  .treatment-name-container {
    width: 40%;
  }
}

#classification-list-accident-date-div {
  display: flex;
  flex-direction: row;

  mat-form-field {
    width: auto;
    height:auto;


  }

  .mat-mdc-form-field-flex {
    display: flex;
    flex-direction: row;
    height: 30px;
  }

  button {
    padding: 0;
  }

  mat-datepicker-toggle {
    zoom: 0.8;
  }

  mat-label {
    width: auto;
  }

}