.form-field {
  display: flex;
  flex-direction: column;
  gap: 10px;

  label {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    color: var(--color-secondary);
  }

  input {
    background-color: var(--bg-input);
    border-radius: var(--br-form-field);
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: var(--color-primary);
    padding: 10px 20px;
    transition: border-color 0.15s ease-in-out;
    border: 1px solid var(--border-color-input);
    width: 100%;

    &:focus {
      outline: none;
      border-color: var(--border-color-focused);
      box-shadow: none;
    }

    &::placeholder {
      color: var(--color-placeholder);
    }

    &.ng-invalid.ng-touched {
      border-color: var(--color-error);
    }
  }

  textarea {
    border-radius: var(--br-textarea);
    transition: border-color 0.15s ease-in-out;
    border: 1px solid var(--border-color-input);
    padding: 10px 20px;
    resize: none;
    outline: none;

    &:focus {
      outline: none;
      border-color: var(--border-color-focused);
      box-shadow: none;
    }
  }

  .radio {
    display: flex;
    flex-direction: row;
    gap: 24px;
  }

  input[type="radio"] {
    display: none;
  }

  input[type="radio"] + label {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
    background-color: var(--bg-choice-inactive);
    color: var(--color-choice-inactive);
    padding: 10px 30px;
    border-radius: var(--br-form-field);
    transition: background-color 0.15s ease-in-out, color 0.15s ease-in-out;
  }

  input[type="radio"]:checked + label {
    color: var(--color-secondary);
    background-color: var(--bg-choice-active);
  }

  .form-field-date {
    position: relative;
    display: flex;

    mat-datepicker-toggle {
      position: absolute;
      right: 10px;
    }
  }

  mat-hint {
    margin-top: -5px;

    font-size: 12px;
    line-height: 16px;
    color: var(--color-secondary);
  }

  ul {
    list-style: disc;
    padding-left: 20px;

    li {
      font-size: 14px;
      line-height: 18px;
      color: var(--color-secondary);
    }
  }
}

.form-field.row {
  display: flex;
  flex-direction: row;
  gap: 24px;
  flex-wrap: wrap;

  .form-field {
    flex: 1;
  }
}

ul {
  list-style: disc;
  padding-left: 20px;

  li {
    font-size: 14px;
    line-height: 18px;
  }
}

.treatment {
  mat-form-field {
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
      padding: 0;
      padding-top: 10px;

      .mat-mdc-floating-label.mdc-floating-label {
        left: 0px;
        top: 14px;
      }
    }
  }
}

cpf-mfv-claim-detail .item{
  mat-form-field {
    .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
      padding: 0;
      min-height: 20px;

      .mat-mdc-floating-label.mdc-floating-label {
        left: 0px;
        top: 14px;
      }
    }
  }
}
