:root {
  --bg-header: #002a32;
  --bg-footer: #002a32;
  --bg-stepper: #002a32;
  --bg-body: #f1f6f7;

  --bg-input: #ffffff;
  --color-placeholder: #b9c5ca;
  --border-color-input: #f1f6f7;
  --border-color-focused: #00686e;

  --bg-button: #e1ff00;
  --bg-button-inactive: #e8edf0;

  --color-primary: #002a32;
  --color-secondary: #00686e;
  --color-error: #f23030;
  --color-choice-inactive: #b9c5ca;
  --bg-choice-active: #ffffff;
  --color-info-row-title: #606473;

  --color-phone-number: #ffffff;
  --color-language-choice: #ffffff;

  --color-stepper-text: #ffffff;
  --color-stepper-line: #ffffff;
  --color-stepper-line-progressed: #00686e;
  --color-stepper-icon: #00686e;
  --color-stepper-active: #e1ff00;

  --chip-text-color: #e8edf0;
  --chip-bg-color: #00686e;

  // GENERATED BY CHATGPT, WILL TRY HOT IT'S WORKS

  --primary-color: #00686e;
  --lighter-primary-color: #409996;
  --darker-primary-color: #004242;

  --secondary-color: #84bdb1;
  --lighter-secondary-color: #b6e8df;
  --darker-secondary-color: #537f85;

  --accent-color: #ff6000;
  --lighter-accent-color: #ff933b;
  --darker-accent-color: #c43d00;

  --warning-color: #f2c94c;
  --success-color: #6fcf97;
  --danger-color: #eb5757;

  --text-color: #333333;
  --text-color-white-bg: #ffffff;

  --tonedBlackGrey025: #f8f8fa;
  --tonedBlackGrey050: #f0f0f5;
  --tonedBlackGrey075: #e9e9ee;
  --tonedBlackGrey100: #c7c4cc;
  --tonedBlackGrey200: #a7a5af;
  --tonedBlackGrey300: #898a8e;
  --tonedBlackGrey500: #6c6d72;
  --tonedBlackGrey700: #43454c;
  --tonedBlackGrey900: #2c2f37;

  --white: #fff;
  --white-disabled: #FFFFFF60;

  --label-simple: #00686e;
  --label-multiple: #b6e8df;
  --label-waiting: #f23030;
  --label-simplego: #f2c94c;


  // Border radius
  // Buttons and inputs
  --br-form-field: 52px;
  --br-textarea: 16px;
  --br-file-drop: 16px;
  --br-stepper-circle: 50%;

  /* Logo sizing */
  --width-logo: 100px;
  --height-logo: 30px;
  --phone-icon-width: 32px;

  /* Header sizing */
  --CPF-header-height: 80px;
}
