@font-face {
  font-family: euclid-circularb-semibold;
  src: url("../assets/fonts/EuclidCircularB-Semibold.otf") format("opentype");
}

@font-face {
  font-family: euclid-circularb-regular;
  src: url("../assets/fonts/EuclidCircularB-Regular.otf") format("opentype");
}

@font-face {
  font-family: euclid-circularb-regularitalic;
  src: url("../assets/fonts/EuclidCircularB-RegularItalic.otf") format("opentype");
}

* {
  font-family: euclid-circularb-regular;
  font-style: normal;
  font-size: x-small;
}