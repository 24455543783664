@import "variables";

.mdc-snackbar.mat-mdc-snack-bar-container.toast-error {
  .mdc-snackbar__surface {
    background-color: var(--errorLightest);
    border: 1px solid var(--errorLighter);
    border-radius: 4px;

    .mat-mdc-snack-bar-label {
      color: var(--errorPrimary);
    }
  }
}
