.table-container {
  width: 600px;
  max-width: 600px;
  padding: 10px 60px 0 60px;
}

.logo {
  zoom: 0.5;
}

table {
  width: 100%;
  border-spacing: 0;
  font-size: 12px;

  tbody {
    width: 100%;
  }

  tr {
    width: 100%;

    &.case-name {
      td {
        font-weight: bold;
        font-size: 14px;
      }
    }

    &.empty {
      height: 20px;
    }

    &.border-outside {
      td:nth-child(2), td:nth-child(3) {
        text-align: center;
      }

      td {
        &:first-child {
          border-left: 1px solid #000;
        }

        &:last-child {
          border-right: 1px solid #000;
          text-align: end;
        }
      }

      &.border-top {
        td {
          border-top: 1px solid #000;
        }
      }

      &.border-bottom {
        td {
          border-bottom: 1px solid #000;
        }
      }
    }
  }

  td {
    min-height: 20px;
    padding: 0 5px;
    margin: 0;
    font-family: euclid-circularb-regular;
  }
}
